@import '../../../../scss/theme-bootstrap';

.product-full__content {
  .product {
    &__button--add-to-bag,
    &__button--loading {
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      a {
        width: 100%;
        &.button--disabled {
          background: $color-grey;
          cursor: default;
          border-color: $color-dark-black;
          color: $white;
        }
      }
    }
    &-add-to-waitlist {
      width: 100%;
    }
  }
}

.add-cart {
  &__response {
    #cboxLoadedContent {
      padding: 30px 10px 20px;
      @include breakpoint($landscape-up) {
        padding: 10px;
      }
    }
  }
}
